const topFloatBnr = (() => {
  const pageTop = (document.getElementById("pageTop") != null);
  if (pageTop) {



    let secFloat = document.getElementById('jsSecFloat');
    let floatWrap = secFloat.querySelector('.js-float-wrap');
    let close = floatWrap.querySelector('.js-close');


    close.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      
      floatWrap.classList.add('none');
    });



  }
})()
export default topFloatBnr;